:root{
    /* animations constants */
    --steps: 10;
    --saturation: 80%;
    --lightness: 60%;
    --hue-offset: 320;
    --duration: 3000ms;

    /* generate some colors */
    --color-01: #054234;
    --color-02: #094837;
    --color-03: #085240;
    --color-04: #0a5d4a;
    --color-05: #0e6550;
    --color-06: #117055;
    --color-07: #0d775d;
    --color-08: #0f886b;
    --color-09: #139f7e;
    --color-10: #18b28d;
    --color-11: #1dd7ab;
    --color-crayon: rgb(8, 28, 7);

    /* build some backgrounds */
    --bg-01-a: no-repeat left       0% top    / 11%   0% linear-gradient(to right, var(--color-01), var(--color-02));
    --bg-01-b: no-repeat left       0% top    / 11% 100% linear-gradient(to right, var(--color-01), var(--color-02));
    --bg-02-a: no-repeat left   11.11% bottom / 11%   0% linear-gradient(to right, var(--color-02), var(--color-03));
    --bg-02-b: no-repeat left   11.11% bottom / 11% 100% linear-gradient(to right, var(--color-02), var(--color-03));
    --bg-03-a: no-repeat left   22.22% top    / 11%   0% linear-gradient(to right, var(--color-03), var(--color-04));
    --bg-03-b: no-repeat left   22.22% top    / 11% 100% linear-gradient(to right, var(--color-03), var(--color-04));
    --bg-04-a: no-repeat left   33.33% bottom / 11%   0% linear-gradient(to right, var(--color-04), var(--color-05));
    --bg-04-b: no-repeat left   33.33% bottom / 11% 100% linear-gradient(to right, var(--color-04), var(--color-05));
    --bg-05-a: no-repeat left   44.44% top    / 11%   0% linear-gradient(to right, var(--color-05), var(--color-06));
    --bg-05-b: no-repeat left   44.44% top    / 11% 100% linear-gradient(to right, var(--color-05), var(--color-06));
    --bg-06-a: no-repeat left   55.55% bottom / 11%   0% linear-gradient(to right, var(--color-06), var(--color-07));
    --bg-06-b: no-repeat left   55.55% bottom / 11% 100% linear-gradient(to right, var(--color-06), var(--color-07));
    --bg-07-a: no-repeat left   66.66% top    / 11%   0% linear-gradient(to right, var(--color-07), var(--color-08));
    --bg-07-b: no-repeat left   66.66% top    / 11% 100% linear-gradient(to right, var(--color-07), var(--color-08));
    --bg-08-a: no-repeat left   77.77% bottom / 11%   0% linear-gradient(to right, var(--color-08), var(--color-09));
    --bg-08-b: no-repeat left   77.77% bottom / 11% 100% linear-gradient(to right, var(--color-08), var(--color-09));
    --bg-09-a: no-repeat left   88.88% top    / 11%   0% linear-gradient(to right, var(--color-09), var(--color-10));
    --bg-09-b: no-repeat left   88.88% top    / 11% 100% linear-gradient(to right, var(--color-09), var(--color-10));
    --bg-10-a: no-repeat left   99.99% bottom / 11%   0% linear-gradient(to right, var(--color-10), var(--color-11));
    --bg-10-b: no-repeat left   99.99% bottom / 12% 100% linear-gradient(to right, var(--color-10), var(--color-11));
}

.body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 40vh;

}

.rainbow-marker-loader {
    height:4rem;
    width:20rem;
    max-width:100%;
    border:.5rem solid var(--color-crayon);
    border-radius:.5rem;
    animation: infinite alternate rainbow-fill var(--duration) ease-in-out;
    box-sizing:border-box;
    position:relative;
    margin:1rem;
    background:
            var(--bg-01-a),
            var(--bg-02-a),
            var(--bg-03-a),
            var(--bg-04-a),
            var(--bg-05-a),
            var(--bg-06-a),
            var(--bg-07-a),
            var(--bg-08-a),
            var(--bg-09-a),
            var(--bg-10-a);
}

@keyframes rainbow-fill{
    0%{
        background:
                var(--bg-01-a),
                var(--bg-02-a),
                var(--bg-03-a),
                var(--bg-04-a),
                var(--bg-05-a),
                var(--bg-06-a),
                var(--bg-07-a),
                var(--bg-08-a),
                var(--bg-09-a),
                var(--bg-10-a);
    }
    10%{
        background:
                var(--bg-01-b),
                var(--bg-02-a),
                var(--bg-03-a),
                var(--bg-04-a),
                var(--bg-05-a),
                var(--bg-06-a),
                var(--bg-07-a),
                var(--bg-08-a),
                var(--bg-09-a),
                var(--bg-10-a);
    }
    20%{
        background:
                var(--bg-01-b),
                var(--bg-02-b),
                var(--bg-03-a),
                var(--bg-04-a),
                var(--bg-05-a),
                var(--bg-06-a),
                var(--bg-07-a),
                var(--bg-08-a),
                var(--bg-09-a),
                var(--bg-10-a);
    }
    30%{
        background:
                var(--bg-01-b),
                var(--bg-02-b),
                var(--bg-03-b),
                var(--bg-04-a),
                var(--bg-05-a),
                var(--bg-06-a),
                var(--bg-07-a),
                var(--bg-08-a),
                var(--bg-09-a),
                var(--bg-10-a);
    }
    40%{
        background:
                var(--bg-01-b),
                var(--bg-02-b),
                var(--bg-03-b),
                var(--bg-04-b),
                var(--bg-05-a),
                var(--bg-06-a),
                var(--bg-07-a),
                var(--bg-08-a),
                var(--bg-09-a),
                var(--bg-10-a);
    }
    50%{
        background:
                var(--bg-01-b),
                var(--bg-02-b),
                var(--bg-03-b),
                var(--bg-04-b),
                var(--bg-05-b),
                var(--bg-06-a),
                var(--bg-07-a),
                var(--bg-08-a),
                var(--bg-09-a),
                var(--bg-10-a);
    }
    60%{
        background:
                var(--bg-01-b),
                var(--bg-02-b),
                var(--bg-03-b),
                var(--bg-04-b),
                var(--bg-05-b),
                var(--bg-06-b),
                var(--bg-07-a),
                var(--bg-08-a),
                var(--bg-09-a),
                var(--bg-10-a);
    }
    70%{
        background:
                var(--bg-01-b),
                var(--bg-02-b),
                var(--bg-03-b),
                var(--bg-04-b),
                var(--bg-05-b),
                var(--bg-06-b),
                var(--bg-07-b),
                var(--bg-08-a),
                var(--bg-09-a),
                var(--bg-10-a);
    }
    80%{
        background:
                var(--bg-01-b),
                var(--bg-02-b),
                var(--bg-03-b),
                var(--bg-04-b),
                var(--bg-05-b),
                var(--bg-06-b),
                var(--bg-07-b),
                var(--bg-08-b),
                var(--bg-09-a),
                var(--bg-10-a);
    }
    90%{
        background:
                var(--bg-01-b),
                var(--bg-02-b),
                var(--bg-03-b),
                var(--bg-04-b),
                var(--bg-05-b),
                var(--bg-06-b),
                var(--bg-07-b),
                var(--bg-08-b),
                var(--bg-09-b),
                var(--bg-10-a);
    }
    100%{
        background:
                var(--bg-01-b),
                var(--bg-02-b),
                var(--bg-03-b),
                var(--bg-04-b),
                var(--bg-05-b),
                var(--bg-06-b),
                var(--bg-07-b),
                var(--bg-08-b),
                var(--bg-09-b),
                var(--bg-10-b);
    }
}



.title{
    color:var(--color-crayon);
    font-size:1.5rem;
    font-family: 'M PLUS Rounded 1c', sans-serif;
    text-transform:uppercase;
    letter-spacing:1px;
}