.Splash-header {
    background-color: #007558;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Splash-logo {
    width: 300px;
    height: 300px;
    animation: Splash-logo-fade ease-in-out 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes Splash-logo-fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}