.Main {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-block: 2rem;
    gap: 2rem;
}

.Input-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    padding-right: 12px;
    padding-left: 12px;
}

.empty {
    height: 120px;
}

.drawer {
    background-color: #0d775d;
    position: absolute;
    right: 12px;
}

.drawer_btn {
    color: #007558;
}

.paginationButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.pagBut {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-style: none;
    color: #0d775d;
    background-color: #ffffff;
}

.pagBut:hover {
    opacity: 0.9;
}

.pagBut:active {
    opacity: 0.7;
}

@media only screen and (min-width: 725px) {
    .Main {
        background-size: 0;
    }
}