
.button {
    align-items: center;
    background-color: #007558;
    border: 2px solid  #007558;
    border-radius: 8px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    height: 48px;
    justify-content: center;
    line-height: 24px;
    max-width: 100%;
    position: relative;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 220px;
}

.button:after {
    background-color: #055945;
    border-radius: 8px;
    content: "";
    display: block;
    height: 48px;
    left: 0;
    width: 100%;
    position: absolute;
    top: -2px;
    transform: translate(8px, 8px);
    transition: transform .2s ease-out;
    z-index: -1;
}

.button:hover:after {
    transform: translate(0, 0);
}

.button:active {
    background-color: #007558;
    outline: 0;
}

.button:hover {
    outline: 0;
}

.hr {
    text-decoration: none;
}

@media (min-width: 768px) {
    .button {
        padding: 0 40px;
    }
}

@media (max-width: 750px) or (max-height: 450px) {
    .button {
        height: 30px;
        width: 180px;
    }
    .button:after {
        height: 30px;
    }
}