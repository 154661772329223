.page_404 {
    background: #fff;
    font-family: serif;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page_404  img{ width:100%;}

.text-center {
    padding-top: 20px;
}

.four_zero_four_bg {
    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
}

.link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: #007558;
    margin: 20px 0;
    border-radius: 8px;
    border-color: transparent;
    width: 100%;
}

.link_404:active {
    content: "";
    opacity: 0.8;
}

.contant_box_404 {
    margin-top: -20px;
    margin-bottom: 20px;
    width: 100%
}