.Payment {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.Button {
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
}

.Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.Container__body {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.tinkoffPayRow {
    display: block;
    margin-top: 5%;
    padding-left: 2%;
    width: 300px;
    height: 48px;
}

@media only screen and (min-width: 725px) {
    .Payment {
        background-size: 0;
    }
    .Container {
        width: 60%;
    }
}

@media only screen and (min-width: 1225px) {
    .Container {
        width: 40%;
    }
}