* {
    box-sizing: border-box;
}

.search-box {
    width: fit-content;
    height: fit-content;
    position: relative;
}

.input-search {
    height: 50px;
    width: 50px;
    border-style: none;
    font-size: 16px;
    letter-spacing: 2px;
    outline: none;
    border-radius: 25px;
    transition: all .5s ease-in-out;
    color: #007558;
    background-color: #007558;
}

.input-search::placeholder {
    color: transparent;
    font-size: 16px;
    letter-spacing: 2px;
    font-weight: 100;
}

.input-search:focus::placeholder {
    color: #055945;
}

.btn-search {
    width: 50px;
    height: 50px;
    border-style: none;
    font-size: 20px;
    font-weight: bold;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    right: 0;
    color: #ffffff;
    background-color: transparent;
    pointer-events: painted;
}

.btn-search:focus ~ .input-search {
    width: 200px;
    border-radius: 0;
    background-color: transparent;
    border-bottom: 1px solid rgb(0, 117, 88);
}

.input-search:focus {
    width: 200px;
    border-radius: 0;
    background-color: transparent;
    border-bottom: 1px solid rgb(5, 89, 69);
    transition: all 500ms cubic-bezier(0, 0, 0, 2);
}

.search-icon {
    width: 50px;
    height: 50px;
}

@media only screen and (min-width: 625px) {
    .btn-search:focus ~ .input-search {
        width: 50vw;
    }

    .input-search:focus {
        width: 50vw;
    }
}

@media only screen and (max-width: 450px) {
    .btn-search:focus ~ .input-search {
        width: 40vw;
    }

    .input-search {
        height: 30px;
        width: 30px;
    }

    .input-search:focus {
        width: 40vw;
    }

    .btn-search {
        width: 30px;
        height: 30px;
        right: 2px;
    }

    .search-icon {
        width: 30px;
        height: 30px;
    }
}